import React, { useContext, ReactNode, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";

export interface User {
  name: string;
  email: string;
  sharing: boolean;
  true_user_id: number;
  true_user_name: string;
  true_user_email: string;
  has_global_access: boolean;
  has_email_scope: boolean;
}

export interface Features {
  test_chat: boolean;
}

interface AppProviderProps {
  user: User | null;
  features: Features | null;
}

export const AppContext = React.createContext<null | AppProviderProps>(null);

interface AppProviderType {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderType) => {
  const posthog = usePostHog();
  const userQuery = useQuery<User>({
    queryKey: ["me"],
    queryFn: () =>
      fetch("/me", {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
  });

  const featureQuery = useQuery<Features>({
    queryKey: ["features"],
    queryFn: () =>
      fetch("/features", {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res.json()),
  });

  useEffect(() => {
    if (userQuery.data) {
      posthog?.identify(`${userQuery.data.true_user_id}`, {
        email: userQuery.data.true_user_email,
        name: userQuery.data.true_user_name,
      });
    }
  }, [userQuery.data]);

  if (userQuery.isLoading || featureQuery.isLoading) {
    return null;
  }

  if (userQuery.error || featureQuery.error) {
    return "error";
  }

  if (!userQuery.data || !featureQuery.data) {
    return null;
  }

  return (
    <AppContext.Provider value={{ user: userQuery.data, features: featureQuery.data }}>
      {children}
    </AppContext.Provider>
  );
};

export function useApp() {
  const value = useContext(AppContext);
  if (value === null) {
    throw new Error("useApp must be wrapped in a <AppProvider />");
  }
  return value;
}
